<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'master-partner-shipper',
};
</script>

<style>
</style>
